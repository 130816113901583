
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of users</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultRole" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by role --</option>
              <option v-for="(role, tIndex) in roleOptions"
                      :value="role.value" :key="tIndex" > {{role.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchUsers()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="items"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(check)="data">
                <div style="width:3rem;" class="form-check text-center me-auto">
                  <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                </div>
              </template>
              <template v-slot:cell(image)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <img class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name || data.item.username }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(email)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.email }}</span>
                </div>
              </template>
              <template v-slot:cell(country)="data">
                <div v-if="data.item.country" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.country.name }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.status }}</span>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <ul class="list-inline table-action m-0">
                  <li class="list-inline-item">
                    <a href="javascript:void(0);" v-if="data.item.id != activeUser.id" @click="deleteItem(data.item.id)" class="text-danger">
                      <i class="ri-delete-bin-2-line"></i></a>
                  </li>
                </ul>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "user-list",
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      defaultRole: "all",
      searchQuery:"",
      roleOptions:[
        {value: "all", label: "All", },
        {value: "admin", label: "Admin"},
        {value: "partner", label: "Partner"},
        {value: "customer", label: "Customer"},
        {value: "technician", label: "Technician"}
      ],
      pageOptions: [10, 25, 50, 100],
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "ID"
        },
        {
            key: "image",
            label: "Image",
            sortable: true
        },
        {
            key: "name",
            label: "Name",
            sortable: true
        },
        {
            key: "email",
            label: "Email",
            sortable: true
        },
        {
            key: "country",
            label: "Country",
            sortable: true
        },
        {
            key: "status",
            label: "Status",
            sortable: true
        },
        "action"
      ],
      selected: [],
      selectedUsers: []
    }
  },
  watch: {
    "$route.query" : "refreshQueryRole",
    defaultRole (val) {
      this.fetchUsers()
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.userList.pageData
    },
    items() {
      return this.pageData.data.filter((user) => this.defaultRole == "all" 
            || (user.role == this.defaultRole))
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchUsers(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchUsers(1, val)
      }
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.items.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    refreshQueryRole(){
      if(this.$route.query.role){
        this.defaultRole = this.$route.query.role
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchUsers()
    }, 2000),
    fetchUsers(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultRole != 'all'){ payload.role = this.defaultRole }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("userList/fetchUsers", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete a user",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/users/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('userList/DELETE_USER', itemId)
            }
          })
        }
      });
    },
  },
  created() {
    this.refreshQueryRole()
    if(this.defaultRole == "all"){this.fetchUsers()}
  }
}

</script>

